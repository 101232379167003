import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import socket from '../../package/socket/index';

class ListenerRealtime extends React.Component {
  constructor(props) {
    super(props);
    this.socketListener = this.socketListener.bind(this);
    this.timeOut = null;
    this.clearAll = this.clearAll.bind(this);
  }

  clearAll() {
    const { onSocket } = this.props;
    socket.removeListener(onSocket, this.socketListener);
    clearTimeout(this.timeOut);
  }

  componentWillUnmount() {
    this.clearAll();
  }

  socketListener() {
    clearTimeout(this.timeOut);

    const { onRefresh } = this.props;
    this.timeOut = setTimeout(() => {
      onRefresh();
    }, process.env.REACT_APP_TIME_OUT_REALTIME);
  }

  componentDidMount() {
    const { onSocket } = this.props;
    socket.on(onSocket, this.socketListener);
  }

  render() {
    return (<></>);
  }
}

ListenerRealtime.propTypes = {
  onRefresh: PropTypes.func,
  onSocket: PropTypes.string,
};

ListenerRealtime.defaultTypes = {
  onRefresh: () => {},
  onSocket: '',
};

export { ListenerRealtime };
