import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../_metronic';
import Builder from './Builder';
import Dashboard from './Dashboard';
import DocsPage from './docs/DocsPage';

import Layout from '../layout';

const GoogleMaterialPage = lazy(() => import('./google-material/GoogleMaterialPage'));
const ReactBootstrapPage = lazy(() => import('./react-bootstrap/ReactBootstrapPage'));

const PowerWarning = lazy(() => import('./PowerWarning'));
const ListStreetLight = lazy(() => import('./ListStreetLight'));
const ListStreetLightByGateway = lazy(() => import('./ListStreetLight/container/ListStreetLightByGatewayContainer'));
const ListGateway = lazy(() => import('./ListGateway'));
const DetailLight = lazy(() => import('./DetailLight'));
const DetailGateway = lazy(() => import('./DetailGate'));
// const GoogleMapPage = lazy(() => import('./GoogleMapPage'))
const NewLight = lazy(() => import('./NewLight'));
const NewGateway = lazy(() => import('./NewGateway'));
const Home = lazy(() => import('./Dashboard/index.js'));
const GateWayMap = lazy(() => import('./Gateway/Map/index.js'));
const ReportStatistic = lazy(() => import('./ReportStatistic/index'));
const Report = lazy(() => import('./Report/index.js'));
const GatewayEdit = lazy(() => import('./Gateway/Edit'));
const Chart = lazy(() => import('./Chart'));
const LogList = lazy(() => import('./Log/List'));
const SMS = lazy(() => import('./SMS/index.js'));

export default function HomePage() {
// useEffect(() => {
// console.log('Home page');
// }, []) // [] - is required if you need only one call
// https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Layout>
        <Switch>
          <Redirect exact from="/" to="/gateway/googleMap" />
          <Route path="/gateway/googleMap" component={GateWayMap} />
          <Route path="/report" component={Report} />
          <Route path="/statistic" component={ReportStatistic} />
          {/* <Route path='/googleMap' component={GoogleMapPage}/> */}
          <Route path="/light/list" component={ListStreetLight} />
          <Route path="/light/:id" component={DetailLight} />
          <Route path="/listLightByGateway/:gateway" component={ListStreetLightByGateway} />
          <Route path="/gateway/list" component={ListGateway} />
          <Route path="/gateway/:id/edit" component={GatewayEdit} />
          <Route path="/gateway/:id/detail" component={DetailGateway} />
          <Route path="/new-gateway" component={NewGateway} />
          <Route path="/new-light" component={NewLight} />
          <Route path="/chart" component={Chart} />
          <Route path="/log/list" component={LogList} />

          {/* <Route path='/system-error/on-off-error' component={OnOffErr} /> */}
          <Route path="/crud/power-warning" component={PowerWarning} />

          <Route path="/builder" component={Builder} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/home" component={Home} />
          <Route path="/google-material" component={GoogleMaterialPage} />
          <Route path="/react-bootstrap" component={ReactBootstrapPage} />
          <Route path="/docs" component={DocsPage} />
          <Route path="/sms" component={SMS} />
          <Redirect to="/error/error-v1" />
        </Switch>
      </Layout>
    </Suspense>
  );
}
