import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
// import * as auth from '../../store/ducks/auth.duck'
import api from 'app/api/api';
import auth from 'app/common/auth';
import { get } from 'lodash';
import { getPath } from '../../common/function';

// import { login } from '../../crud/auth.crud'

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: '2.5rem',
  });
  const [login, setLogin] = useState('');

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: '3.5rem' });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: '2.5rem' });
  };

  return (
    <>
      {/* <div className="kt-login__head"> */}
      {/*  <span className="kt-login__signup-label"> */}
      {/*    Don't have an account yet? */}
      {/*  </span> */}
      {/*  &nbsp;&nbsp; */}
      {/*  <Link to="/auth/registration" className="kt-link kt-login__signup-link"> */}
      {/*    Sign Up! */}
      {/*  </Link> */}
      {/* </div> */}

      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validate={(values) => {
              const errors = {};

              if (!values.email) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.email = intl.formatMessage({
                  id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: 'AUTH.VALIDATION.INVALID_FIELD',
                });
              }

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                });
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              api('api/login', 'POST', {
                email: values.email,
                password: values.password,
              }).then((response) => {
                disableLoading();
                if (get(response, 'status') === 422) { return setLogin(get(response, 'data.message')); }
                auth.set(response);
                props.history.push(getPath('home')());
              });
              // setTimeout(() => {
              //   login(values.email, values.password)
              //     .then(({ data: { accessToken } }) => {
              //       disableLoading()
              //       props.login(accessToken)
              //     })
              //     .catch(() => {
              //       disableLoading()
              //       setSubmitting(false)
              //       setStatus(
              //         intl.formatMessage({
              //           id: 'AUTH.VALIDATION.INVALID_LOGIN',
              //         }),
              //       )
              //     })
              // }, 1000)
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                noValidate
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {/* {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <div role="alert" className="alert alert-info">
                    <div className="alert-text">
                      Use account <strong>admin@demo.com</strong> and password{' '}
                      <strong>demo</strong> to continue.
                    </div>
                  </div>
                )} */}

                <div className="form-group">
                  <TextField
                    type="email"
                    label="Email"
                    margin="normal"
                    className="kt-width-full"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Mật khẩu"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>
                {
                  login && (
                  <div className="form-group">
                    <div className="text-danger">{login}</div>
                  </div>
                  )
                }
                <div className="kt-login__actions">
                  {/* <Link */}
                  {/*  to="/auth/forgot-password" */}
                  {/*  className="kt-link kt-login__link-forgot" */}
                  {/* > */}
                  {/*  <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" /> */}
                  {/* </Link> */}

                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={loading}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
                      },
                    )}`}
                    style={loadingButtonStyle}
                  >
                    Đăng nhập
                  </button>
                </div>
              </form>
            )}
          </Formik>

          {/* <div className="kt-login__divider">
            <div className="kt-divider">
              <span />
              <span>OR</span>
              <span />
            </div>
          </div>

          <div className="kt-login__options">
            <Link to="http://facebook.com" className="btn btn-primary kt-btn">
              <i className="fab fa-facebook-f" />
              Facebook
            </Link>
            <Link to="http://twitter.com" className="btn btn-info kt-btn">
              <i className="fab fa-twitter" />
              Twitter
            </Link>
            <Link to="google.com" className="btn btn-danger kt-btn">
              <i className="fab fa-google" />
              Google
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    // auth.actions,
  )(withRouter(Login)),
);
