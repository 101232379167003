import React from 'react';
import {
  Link, Switch, Route, Redirect,
} from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_metronic';
import '../../../_metronic/_assets/sass/pages/login/login-1.scss';
import Login from './Login';
import Registration from './Registration';
import ForgotPassword from './ForgotPassword';

export default function AuthPage() {
  const today = new Date().getFullYear();

  return (
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <div
        id="kt_login"
        className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
      >
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
          <div
            className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-4.jpg')})`,
            }}
          >
            <div className="kt-grid__item">
              <Link to="/" className="kt-login__logo">
                <img
                  width="100px"
                  alt="Logo"
                  src={toAbsoluteUrl('/media/logos/logo-4.png')}
                />
              </Link>
            </div>
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
              <div className="kt-grid__item kt-grid__item--middle">
                <h3 className="kt-login__title">Đèn chiếu sáng thông minh</h3>
                <h4 className="kt-login__subtitle">
                  Hệ thống đèn chiếu sáng công cộng tại Việt Nam.
                </h4>
              </div>
            </div>
            <div className="kt-grid__item">
              <div className="kt-login__info">
                <div className="kt-login__copyright">
                  {today.toString()}
&nbsp;&copy;&nbsp;
                  <a
                    href="http://keenthemes.com/metronic"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="kt-link"
                  >
                    Đèn chiếu sáng thông  minh
                  </a>
                </div>
                <div className="kt-login__menu">
                  <a
                    href="http://keenthemes.com/metronic"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="kt-link"
                  >
                    Chi tiết
                  </a>
                  <a
                    href="http://keenthemes.com/metronic"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="kt-link"
                  >
                    Nhóm
                  </a>
                  <a
                    href="http://keenthemes.com/metronic"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="kt-link"
                  >
                    Liên hệ
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
            <Switch>
              <Route path="/auth/login" component={Login} />
              <Route path="/auth/registration" component={Registration} />
              <Route
                path="/auth/forgot-password"
                component={ForgotPassword}
              />
              <Redirect from="/auth" exact to="/auth/login" />
              <Redirect to="/auth/login" />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
