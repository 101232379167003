import io from 'socket.io-client';

function getSocket() {
  const socket = io(`${process.env.REACT_APP_SOCKET_SERVER}:${process.env.REACT_APP_SOCKET_PORT}`);
  socket.on('connect', () => {
    console.log('socket.io-client connect');
  });
  return socket;
}

export default getSocket();
