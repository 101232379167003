import { ROUTE } from './const';

function topicParse(_topic) {
  const topic = _topic.split('/');
  return {
    GID: topic[0].split('-')[0],
    NID: topic[1],
  };
}

function getParams(url, param) {
  const obj = new URL(url);
  return obj.searchParams.get(param) || '';
}

function localTime(time, format = process.env.REACT_APP_DATE_FORMAT) {
  return time;
}

export function validate(className) {
  const validateErrorElement = document.getElementsByClassName(className || 'text-error');
  if (validateErrorElement.length > 0) {
    for (let index = 0; index < validateErrorElement.length; ++index) {
      validateErrorElement[index].setAttribute('style', 'display: block !important');
    }
    window.scrollTo({
      top: validateErrorElement[0].offsetTop,
      left: 0,
      behavior: 'smooth',
    });
    return false;
  }
  return true;
}

function getPath(alias) {
  return ROUTE[alias];
}

const parseGatewayToId = (gateways) => gateways.map((val) => val.GID);

export {
  topicParse,
  getParams,
  localTime,
  getPath,
  parseGatewayToId,
};
