import React, { Component } from 'react';
// import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
// import * as auth from '../../store/ducks/auth.duck'
import auth from 'app/common/auth';
import { getPath } from 'app/common/function';
import { LayoutSplashScreen } from '../../../_metronic';

class Logout extends Component {
  constructor(props) {
    super(props);
    auth.del();
  }

  componentDidMount() {
    // this.props.logout()
  }

  render() {
    // const { hasAuthToken } = this.props

    return auth.isAuth() ? <LayoutSplashScreen /> : <Redirect to={getPath('login')} />;
  }
}

export default Logout;

// export default connect(
//   ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
//   auth.actions,
// )(Logout)
